    <h1 class="page-title">{{ prodTitle }} - <span t7e="title-h1">Dashboard</span></h1>

    <div class="warnings lang-selector mb-1">
        <app-lang-selector *ngIf="!isLangSelected"></app-lang-selector>
    </div>
                <!-- <button mat-icon-button type="button" class="btn btn-primary" (click)="reloadAppData()" color="primary"
                    [disabled]="isUsersLoading$ | async">
                    <mat-icon *ngIf="!(isUsersLoading$ | async)">refresh</mat-icon>
                    <mat-spinner [diameter]="15" *ngIf="isUsersLoading$ | async"></mat-spinner>
                </button> -->

    <!-- WARNINGS -->
    <div class="warnings">
        <ng-template #notCrew>
            <div class="disabled-user-wrapper">
                <app-page-warning [title]="t7eCongratsTitle"
                    [description]="t7eCongratsDescr"
                    [cssClass]="'danger'"
                    class="user-disabled">
                </app-page-warning>
                <button mat-icon-button type="button" class="btn btn-primary" 
                    (click)="reloadAppData()" color="primary"
                    [disabled]="isUsersLoading$ | async">
                    <mat-icon *ngIf="!(isUsersLoading$ | async)">refresh</mat-icon>
                    <mat-spinner [diameter]="15" *ngIf="isUsersLoading$ | async"></mat-spinner>
                </button>
            </div>
        </ng-template>

        <ng-container *ngIf="!isProfileComplete">
            <app-page-warning [title]="t7eProfileIncompleteTitle"
                [cssClass]="'danger'"
                class="profile-incomplete"
                [routerLink]="['/','my', 'profile']">
            </app-page-warning>
        </ng-container>

        <div *ngIf="debug">{{ atCrewOwnContracts$ | async | json }}</div>
        <ng-container *ngIf="(atCrewOwnContracts$ | async)?.length">
            <app-page-warning [title]="t7eContractsAtCrewTitle"
                [cssClass]="'danger'"
                class="contracts-at-crew"
                [routerLink]="['/','my', 'contracts']">
            </app-page-warning>
        </ng-container>
        
        <ng-container *ngIf="(tsAtCrew$ | async)?.length">
            <app-page-warning [title]="t7eTsAtCrewTitle"
                [cssClass]="'danger'"
                class="ts-at-crew">
                <span appPageWarningDescription class="ts-list">
                    <ng-container *ngFor="let ts of (tsAtCrew$ | async); let last">
                        <a [routerLink]="['/', 'ts', ts.tsid]">
                            {{ getTSDate(ts) }}
                        </a>
                        <span *ngIf="!last">, </span>
                    </ng-container>
                </span>
            </app-page-warning>
        </ng-container>
        
    </div>

    <!-- YOUR DATA -->
    <section class="section" *ngIf="isCrew$ | async; else notCrew">
        <app-section-title [title]="t7eSectionYourData"></app-section-title>

        <div class="boxes">
            <div class="box is-link" [routerLink]="['/', 'my','profile']">
                <mat-icon>person</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-profile">Profile</div>
                    <div class="detail-line">
                        <mat-spinner [diameter]="15" *ngIf="isUsersLoading$ | async; else showUsername"></mat-spinner>
                        <ng-template #showUsername>
                            <div>{{ (loggedinUser$ | async)?.usname }}</div>
                        </ng-template>
                    </div>
                    <div class="detail-line" *ngIf="isProfileComplete; else profileIncomplete">
                        <mat-spinner [diameter]="15" *ngIf="isUsersLoading$ | async; else showEmail"></mat-spinner>
                        <ng-template #showEmail><div>{{ (loggedinUser$ | async)?.email}}</div></ng-template>
                    </div>
                    <ng-template #profileIncomplete>
                        <div class="detail-line warning-line">
                            <mat-icon>error_outline</mat-icon>
                            <div t7e="box-profile-complete-your-profile">Complete your profile!</div>
                        </div>
                    </ng-template>
                </div>
                <div class="refresh-button">
                    <button mat-icon-button type="button" 
                        (click)="reloadUser($event)" color="secondary"
                        [disabled]="isUsersLoading$ | async">
                        <mat-icon *ngIf="!(isUsersLoading$ | async)">refresh</mat-icon>
                    </button>
                </div>
            </div>

            <div class="box is-link" [routerLink]="['/', 'my', 'contracts']" [ngClass]="{'faded': !isProfileComplete || !(isCrew$|async)}">
                <mat-icon>file_copy</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-my-contracts">My Contracts</div>
                    <div class="detail-line">
                        {{ t7eYouHave }}&nbsp;
                        <span  *ngIf="!(contractsLoading$ | async); else showNumContractsLoading">
                        {{ (userContracts$ | async)?.length }}</span> &nbsp; {{ t7eYouHaveXContracts }}.
                        <ng-template #showNumContractsLoading>
                            <mat-spinner [diameter]="15"></mat-spinner>
                        </ng-template>
                    </div>
                </div>
                <div class="refresh-button">
                    <button mat-icon-button type="button" 
                        (click)="reloadCon($event)" color="secondary"
                        [disabled]="isUsersLoading$ | async" *ngIf="!(isUsersLoading$ | async)">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
            </div>

            <div class="box is-link" [routerLink]="['/', 'my', 'timesheets']" [ngClass]="{'faded': !isProfileComplete || !(isCrew$|async)}">
                <mat-icon>date_range</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-my-ts">My Timesheets</div>
                    <div class="detail-line hidden">
                        <mat-spinner [diameter]="15" *ngIf="isTsLoading$ | async; else showNumTsSent"></mat-spinner>
                        <div><ng-template #showNumTsSent>{{ tsList?.length }}</ng-template> &nbsp;<span t7e="sent">sent</span>.</div>
                    </div>
                    <div class="detail-line hidden">
                        <mat-spinner [diameter]="15" *ngIf="isTsLoading$ | async; else showNumAwaitingInvoice"></mat-spinner>
                        <div><ng-template #showNumAwaitingInvoice>{{ pendingOwnTimesheets?.length }}</ng-template> &nbsp;
                            <span t7e="awaiting-invoice">awaiting your invoice</span>.</div>
                    </div>
                </div>
                <div class="refresh-button hidden">
                    <button mat-icon-button type="button" 
                        (click)="reloadTs($event)" color="secondary"
                        [disabled]="isUsersLoading$ | async">
                        <mat-icon *ngIf="!(isUsersLoading$ | async)">refresh</mat-icon>
                    </button>
                </div>
            </div>

            <div class="box is-link" [routerLink]="['/', 'my', 'wts']"
                [ngClass]="{'faded': !isProfileComplete || !(isCrew$|async)}">
                <mat-icon>attach_money</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-my-wts">Heti Timesheet</div>
                </div>
            </div>

        </div>
    </section>

    <!-- DEPT ADMIN & DEPT LEAD -->
    <section class="section" *ngIf="isDeptAdmin || isDeptHead">
        <app-section-title [title]="t7eSectionDeptData" t7e="section-dept-data"></app-section-title>
    
        <div class="boxes">
            <div class="box is-link" [routerLink]="['/', 'dept', 'users']">
                <mat-icon>person</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-users-title">Felhasználók</div>
                    <div class="detail-line">
                        <mat-spinner [diameter]="15" *ngIf="isUsersLoading$ | async; else showNumCrew"></mat-spinner>
                        <div><ng-template #showNumCrew>{{ (usersInLoggedInUsersDept$ | async)?.length }}</ng-template> 
                            &nbsp;<span t7e="dept-members">department members.</span>
                        </div>
                    </div>
                </div>
                <div class="refresh-button">
                    <button mat-icon-button type="button" (click)="reloadUser($event)" color="secondary"
                        [disabled]="isUsersLoading$ | async">
                        <mat-icon *ngIf="!(isUsersLoading$ | async)">refresh</mat-icon>
                    </button>
                </div>
            </div>

            <div class="box is-link" [routerLink]="['/', 'dept', 'contracts']" *ngIf="!isOnlyDeptAdmin">
                <mat-icon>file_copy</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-startforms-title">Start Formok</div>
                    <div class="detail-line">
                        <mat-spinner [diameter]="15" *ngIf="contractsLoading$ | async; else showNumDeptContracts"></mat-spinner>
                        <ng-template #showNumDeptContracts>{{ (deptContracts$ | async)?.length }} </ng-template> &nbsp;
                        start form<span *ngIf="lang=='en'">s</span>.
                    </div>
                </div>
                <div class="refresh-button">
                    <button mat-icon-button type="button" (click)="reloadCon($event)" color="secondary"
                        [disabled]="contractsLoading$ | async">
                        <mat-icon *ngIf="!(contractsLoading$ | async)">refresh</mat-icon>
                    </button>
                </div>
            </div>

            <div class="box is-link" [routerLink]="['/', 'dept', 'ts']">
                <mat-icon>date_range</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-timesheets-title">Timesheetek</div>
                    <div class="detail-line hidden">
                        <mat-spinner [diameter]="15" *ngIf="isTsLoading$ | async; else showNumDeptTss"></mat-spinner>
                        <ng-template #showNumDeptTss>{{ (deptTsList$ | async)?.length }} </ng-template> &nbsp;
                        timesheet<span *ngIf="lang=='en'">s</span>.
                    </div>
                </div>
                <div class="refresh-button hidden">
                    <button mat-icon-button type="button" (click)="reloadTs($event)" color="secondary"
                        [disabled]="isTsLoading$ | async">
                        <mat-icon *ngIf="!(isTsLoading$ | async)">refresh</mat-icon>
                    </button>
                </div>
            </div>

        </div>
    </section>

    <!-- PRODUCTION MANAGER -->
    <section class="section" *ngIf="isMoreThanDeptHead">
        <app-section-title [title]="t7eSectionProduction"></app-section-title>

        <div class="boxes">
            <div class="box is-link" [routerLink]="['/', 'admin', 'users']">
                <mat-icon>people</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-users-title">Users</div>
                    <div class="detail-line">
                        <mat-spinner [diameter]="15" *ngIf="isUsersLoading$ | async; else showNumCrew"></mat-spinner>
                        <div><ng-template #showNumCrew>{{ (users$ | async)?.length }}</ng-template> &nbsp;
                            <span t7e="crew-members">crew members</span>.
                        </div>
                    </div>
                    <div class="detail-line">
                        <mat-spinner [diameter]="15" *ngIf="isUsersLoading$ | async; else showNumCrewPending"></mat-spinner>
                        <div><ng-template #showNumCrewPending>{{ (pendingUsers$ | async)?.length }}</ng-template> &nbsp;
                            <span t7e="pending">pending</span>.
                        </div>
                    </div>
                </div>
                <div class="refresh-button">
                    <button mat-icon-button type="button" 
                        (click)="reloadUser($event)" color="secondary"
                        [disabled]="isUsersLoading$ | async">
                        <mat-icon *ngIf="!(isUsersLoading$ | async)">refresh</mat-icon>
                    </button>
                </div>
            </div>

            <div class="box is-link" [routerLink]="['/', 'contract', 'list-for-pm']">
                <mat-icon>file_copy</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-startforms-title">Start forms</div>
                    <div class="detail-line">
                        <mat-spinner [diameter]="15" *ngIf="contractsLoading$ | async; else showNumContracts"></mat-spinner>
                        <ng-template #showNumContracts>{{ (contracts$ | async)?.length }} </ng-template> &nbsp;start form<span *ngIf="lang=='en'">s</span>.
                    </div>
                    <div class="detail-line">
                        <mat-spinner [diameter]="15" *ngIf="contractsLoading$ | async; else showNumPendingContracts"></mat-spinner>
                        <ng-template #showNumPendingContracts>{{ (prodMgrPendingContracts$ | async)?.length }}</ng-template> &nbsp;
                        <span t7e="pending-approval">pending approval</span>.
                    </div>
                </div>
                <div class="refresh-button">
                    <button mat-icon-button type="button" 
                        (click)="reloadCon($event)" color="secondary"
                        [disabled]="isUsersLoading$ | async">
                        <mat-icon *ngIf="!(isUsersLoading$ | async)">refresh</mat-icon>
                    </button>
                </div>
            </div>

            <div class="box is-link" [routerLink]="['/', 'ts', 'list']">
                <mat-icon>date_range</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-timesheets-title">Timesheets</div>
                    <div class="detail-line hidden">
                        <mat-spinner [diameter]="15" *ngIf="isTsLoading$ | async; else showNumAwaitingTs"></mat-spinner>
                        <div><ng-template #showNumAwaitingTs>{{ pendingProdMgrTimesheets?.length }}</ng-template> 
                            &nbsp;<span t7e="pending-approval">awaiting approval</span>.
                        </div>
                    </div>
                </div>
                <div class="refresh-button hidden">
                    <button mat-icon-button type="button" 
                        (click)="reloadTs($event)" color="secondary"
                        [disabled]="isUsersLoading$ | async">
                        <mat-icon *ngIf="!(isUsersLoading$ | async)">refresh</mat-icon>
                    </button>
                </div>
            </div>

            <div class="box is-link" [routerLink]="['/', 'dispo', 'list']">
                <mat-icon>date_range</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-shootingdays-title">Forgatási napok</div>
                    <div class="detail-line">
                    </div>
                </div>
            </div>

        </div>
    </section>

    <!-- FINANCE -->
    <section class="section" *ngIf="isMoreThanDeptHead">
        <app-section-title [title]="t7eSectionFinance"></app-section-title>

        <div class="boxes">
            <div class="box is-link" [routerLink]="['/', 'contract', 'list']">
                <mat-icon>file_copy</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-contracts-title">Contracts</div>
                    <div class="detail-line" *ngIf="isFinanceApprovalNeededConfig$ | async">
                        <div *ngIf="!(contractsLoading$ | async); else conLoading1">
                            {{ (financePendingContracts$ | async)?.length }}
                        </div>
                        <ng-template #conLoading1>
                            <mat-spinner [diameter]="15" *ngIf="contractsLoading$ | async"></mat-spinner>
                        </ng-template>
                        &nbsp;<span t7e="pending-approval">pending</span>.
                        <div>
                        </div>
                    </div>
                    <div class="detail-line">
                        <div *ngIf="!(contractsLoading$ | async); else conLoading1">
                            {{ (finalApprovedContracts | async)?.length }}
                        </div>
                        <ng-template #conLoading1>
                            <mat-spinner [diameter]="15" *ngIf="contractsLoading$ | async"></mat-spinner>
                        </ng-template>
                        &nbsp;<span t7e="approved">approved</span>.
                        <div>
                        </div>
                    </div>
                    <div class="detail-line">
                        <div *ngIf="!(contractsLoading$ | async); else conLoading2">
                            {{ (readyToSignContracts$ | async)?.length }}
                        </div>
                        <ng-template #conLoading2>
                            <mat-spinner [diameter]="15"></mat-spinner>
                        </ng-template>
                        &nbsp;<span t7e="awaiting-signature">awaiting signature</span>.
                    </div>
                </div>
                <div class="refresh-button">
                    <button mat-icon-button type="button" (click)="reloadCon($event)" color="secondary"
                        [disabled]="isUsersLoading$ | async">
                        <mat-icon *ngIf="!(isUsersLoading$ | async)">refresh</mat-icon>
                    </button>
                </div>
            </div>
            <div class="box is-link" [routerLink]="['/', 'ts', 'generate-invrep']">
                <mat-icon>date_range</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-wts-title">Weekly Time Sheets</div>
                    <div class="detail-line hidden">
                        <mat-spinner [diameter]="15" *ngIf="isTsLoading$ | async; else showNumNotSentTs"></mat-spinner>
                        <div><ng-template #showNumNotSentTs>{{ (tsNotSent$ | async)?.length }}</ng-template>
                            &nbsp;<span t7e="pending-sending">awaiting approval</span>.
                        </div>
                    </div>
                    <div class="detail-line hidden">
                        <mat-spinner [diameter]="15" *ngIf="isTsLoading$ | async; else showNumApprovedTs"></mat-spinner>
                        <div><ng-template #showNumApprovedTs>{{ (tsApproved$ | async)?.length }}</ng-template>
                            &nbsp;<span t7e="approved">approved</span>.
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>

    <!-- REPORTS -->
    <section class="section" *ngIf="isMoreThanDeptHead">
        <app-section-title [title]="t7eSectionReports"></app-section-title>

        <div class="boxes">
            <div class="box is-link" [routerLink]="['/', 'report', 'otta']">
                <mat-icon>euro_symbol</mat-icon>
                <div class="body">
                    <div class="title">OT & TA Grand Total</div>
                    <div class="detail-line">
                    </div>
                </div>
            </div>

            <div class="box is-link" [routerLink]="['/', 'report', 'otta', 'weekly']">
                <mat-icon>euro_symbol</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-weekly-otta-title">Weekly OT TA</div>
                    <div class="detail-line">
                    </div>
                </div>
            </div>

            <div class="box is-link" [routerLink]="['/', 'report', 'salary']">
                <mat-icon>euro_symbol</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-salary-report-title">Napidíjak</div>
                    <div class="detail-line">
                    </div>
                </div>
            </div>

            <div class="box is-link" [routerLink]="['/', 'report', 'ts-summary']">
                <mat-icon>euro_symbol</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-ts-summary-title">Ekho</div>
                    <div class="detail-line">
                    </div>
                </div>
            </div>

        </div>
    </section>

    <!-- INFO -->
    <section class="section mb-1">
        <app-section-title [title]="'Info'"></app-section-title>

        <div class="boxes">
            <a class="box" target="_blank" [href]="invoiceMainualLink">
                <mat-icon>info_outline</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-info-inv-title">Számlázási információk</div>
                </div>
            </a>
            
            <div class="box is-link" [routerLink]="['/', 'crew-list']">
                <mat-icon>format_align_center</mat-icon>
                <div class="body">
                    <div class="title" t7e="box-crew-list">Stáblista</div>
                </div>
            </div>

            <div class="box">
                <mat-icon>alternate_email</mat-icon>
                <div class="body">
                    <div class="title app-box-title" t7e="box-about">A CrewTime App</div>
                    <div class="detail-line" *ngIf="lang=='hu'; else manualEng">
                        Használati útmutató:&nbsp;<a href="https://docs.google.com/document/d/1LCK7RorUpE8PohbT5XEuUpqgmVlEXl_tlwl3sgLGPiU/edit?usp=sharing">itt</a>
                    </div>
                    <ng-template #manualEng>
                        <div class="detail-line">
                            User manual:&nbsp;<a href="https://docs.google.com/document/d/1a-bDtRJzsy4fkiuJcRSQrNoFEKhmf4y6/edit?usp=sharing&ouid=107918629467201434763&rtpof=true&sd=true">here</a>
                        </div>
                        </ng-template>
                        <div class="detail-line" *ngIf="lang=='hu'; else developerEng">
                        Fejlesztő:&nbsp;<a href="mailto:sosandris@gmail.com">@Sós András & co</a>
                    </div>
                    <ng-template #developerEng>
                        <div class="detail-line">
                            Developer:&nbsp;<a href="mailto:sosandris@gmail.com">@András Sós & co</a>
                        </div>
                    </ng-template>
                    <div class="detail-line">
                        <span t7e="version">Verzió</span>: 1.26.5 (2024.11.25.)
                    </div>
                    <div class="detail-line">
                        <mat-spinner [diameter]="15" *ngIf="isAppVersionChecking$ | async" class="mr-05"></mat-spinner>
                        {{ appVersionCheckingMessage$ | async }}
                    </div>
                </div>
                <div class="refresh-button">
                    <button mat-icon-button type="button" (click)="checkForAppUpdates($event)" color="secondary"
                        [disabled]="isAppVersionChecking$ | async">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
            </div>
        </div>

    </section>
 
    <div class="basic-data-dialog" *ngIf="!(loggedUserHasBasicData$ | async)">
        <div class="basic-data-content">
            <div class="basic-user-data-title">
                <span t7e="basic-user-data-title">Üdvözöl a </span>
                {{ prodTitle }}!
            </div>
            <article class="brief">
                <p t7e="basic-user-data-body1">
                    A CrewTime app egy online alkalmazás, amely segítségével a stábtagok és a stábvezetők könnyedén kezelhetik a start formokat, a szerződést és a timesheeteket.
                </p>
                <p t7e="basic-user-data-body2">
                    Kezdetnek adj meg néhány adatot, hogy a gyártásvezető jóvá tudja hagyni a regisztrációdat!
                </p>
            </article>
            <app-basic-user-data ></app-basic-user-data>
        </div>
    </div>